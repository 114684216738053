<template>
  <div id="home">
    <NavBar :currentRoute="'Users'"/>

    <v-container class="flex" id="main-container">
      <v-row justify="center">
        <v-col cols="12" md="11" lg="10" xl="9">
          <Users></Users>
        </v-col>
      </v-row>
    </v-container>

    <About/>
  </div>
</template>

<script>
import About from "@/components/About";
import NavBar from "@/components/NavBar";
import Users from "@/components/user/Users";

export default {
  name: 'UsersView',
  components: {
    NavBar,
    About,
    Users
  }
}
</script>
