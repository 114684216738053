<template>
  <div>
    <v-alert :value="true" type="error" v-if="noUserRolesFound">{{ noUserRolesFoundText }}</v-alert>
    <v-alert type="error" dismissible v-if="errorMessage.length > 0">{{ errorMessage }}</v-alert>
    <v-alert dense text dismissible type="success" v-if="successMessage.length > 0">{{ successMessage }}</v-alert>
    <v-snackbar v-model="noUsersFound" :multi-line="true">
      {{ noUsersFoundText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="noUsersFound = false">
          X
        </v-btn>
      </template>
    </v-snackbar>

    <v-data-table :headers="headers" :items="users" sort-by="username" class="elevation-2"
                  :footer-props="{itemsPerPageText: 'Page rows'}">
      <!--      title, add user button, add/edit/delete user form dialog-->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-spacer></v-spacer>
          <!--          add/update/delete user data dialog-->
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" small outlined v-on="on">
                <v-icon left>
                  mdi-account-plus
                </v-icon>
                Add new user
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field clearable v-model="editingUser.username" label="Emri"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select no-data-text="No userrs found..." :items="availableRoles" v-model="editingUser.role"
                                label="Role"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                          v-model="editingUser.password"
                          label="Password"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPassword ? 'text' : 'password'"
                          hint="At least 6 characters"
                          counter
                          required
                          @click:append="showPassword = !showPassword"
                          autocomplete="off"
                          clearable>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--          delete user dialog confirmation-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title primary-title>
                <div class="mt-1">
                  <div class="headline align-center" style="text-align: center">
                    Confirm deletion of user <b>{{ editingUser.username }}</b>
                  </div>
                  <span class="grey--text text--darken-1">User can be recovered</span>
                  <br>
                  <span class="grey--text text--darken-1">only by contacting the software engineer!</span>
                </div>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" class="mr-6" outlined @click="closeDeleteDialog">Cancel</v-btn>
                <v-btn color="red darken-1" outlined @click="deleteUser">Delete</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!--          edit and delete icons with method events-->
      <template v-slot:item.actions="{item}">
        <v-icon small class="mr-4" @click="editUser(item)">mdi-pencil</v-icon>
        <v-icon small color="red darken-1" @click="deleteUserDialog(item)">mdi-delete</v-icon>
      </template>
      <!--      if list is empty show button to re fetch active user-->
      <template v-slot:no-data>
        <v-btn class="ma-5" color="primary" outlined @click="refresh">
          Reload
        </v-btn>
      </template>
    </v-data-table>

    <v-overlay :value="showOverlay" color="#B4B4B4">
      <v-progress-circular indeterminate size="50" color="amber"/>
    </v-overlay>

  </div>
</template>

<script>
import UserService from "@/service/UserService";
import {BackendError} from "@/exceptions/BackendError";
import {UserInsertDTO} from "@/model/user/UserInsertDTO";
import {UserUpdateDTO} from "@/model/user/UserUpdateDTO";

export default {
  name: "Users",
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New user' : 'Change data';
    }
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    showOverlay: true,

    noUsersFound: false,
    noUsersFoundText: 'No active user found',
    noUserRolesFound: false,
    noUserRolesFoundText: 'Failed to load user roles!',
    errorMessage: '',
    successMessage: '',

    availableRoles: [],
    users: [],
    headers: [
      {
        text: 'Username',
        align: 'start',
        sortable: false,
        value: 'username',
      },
      {text: 'Role', value: 'role'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],

    editedIndex: -1,
    showPassword: false,
    editingUser: {
      id: '',
      username: '',
      role: '',
      password: ''
    },
    defaultUser: {
      username: '',
      role: '',
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDeleteDialog();
    },
  },

  async created() {
    //load user roles
    if (!await this.successfulUserRolesLoad()) {
      this.showOverlay = false;
      return;
    }
    //load active user, show in table
    await this.loadAndShowActiveUsers();
  },

  methods: {
    async refresh() {
      //load user roles
      if (!await this.successfulUserRolesLoad()) {
        return;
      }
      //load active user, show in table
      await this.loadAndShowActiveUsers();
    },

    async successfulUserRolesLoad() {
      let rolesFromBackend;
      try {
        rolesFromBackend = await UserService.getAllRoles();
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
          this.noUserRolesFound = true;
          return false;
        }
        this.errorMessage = "Problem during request handling!";
        this.noUserRolesFound = true;
        return false;
      }
      if (rolesFromBackend.length === 0) {
        this.noUserRolesFound = true;
        return false;
      }
      //trim 'role_' part and make txt lowercase
      for (let i = 0; i < rolesFromBackend.length; i++) {
        this.availableRoles.push(rolesFromBackend[i].replace("ROLE_", "").toLowerCase());
      }
      return true;
    },

    async loadAndShowActiveUsers() {
      let usersResult;
      this.showOverlay = true;
      try {
        usersResult = await UserService.getAllActive();
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
          return;
        }
        this.errorMessage = "Problem during request handling!";
        return;
      } finally {
        this.showOverlay = false;
      }
      if (usersResult === undefined) {
        return;
      }
      if (usersResult.length === 0) {
        this.noUsersFound = true;
        return;
      }
      for (let i = 0; i < usersResult.length; i++) {
        this.users.push(this.convertUserToDTo(usersResult[i]));
      }
    },

    /**
     * trim 'role_' part and make txt lowercase
     */
    convertUserToDTo(user) {
      user.role = user.role.replace("ROLE_", "");
      user.role = user.role.toLowerCase();
      return user;
    },

    /**
     * bind row data to dialog fields
     * @param user
     */
    editUser(user) {
      this.editedIndex = this.users.indexOf(user);
      this.editingUser = Object.assign({}, user);
      this.dialog = true;
    },

    /**
     * store current user, open delete confirmation dialog
     * @param user
     */
    deleteUserDialog(user) {
      this.editedIndex = this.users.indexOf(user);
      this.editingUser = Object.assign({}, user);
      this.dialogDelete = true;
    },

    /**
     * store current user, open delete confirmation dialog
     */
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editingUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
      })
    },

    /**
     * close delete confirmation dialog, reset current/selected user
     */
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editingUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
      })
    },

    /**
     * delete selected user, show notification alert on success/error, remove user form table on success
     */
    async deleteUser() {
      try {
        await UserService.deleteById(this.editingUser.id);
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
          this.closeDeleteDialog();
          return;
        }
        this.errorMessage = "Problem during request handling!";
        this.closeDeleteDialog();
        return;
      }
      this.successMessage = "User is deleted!";
      //remove from list on deletion success
      this.users.splice(this.editedIndex, 1)
      this.closeDeleteDialog();
    },

    /**
     * call update or save user based on value of editedIndex
     * @return {Promise<void>}
     */
    async save() {
      this.errorMessage = '';
      if (this.editedIndex > -1) {
        await this.updateUser(this.editingUser);
      } else {
        await this.addUser(this.editingUser)//.then(() => this.close());
      }
      this.close();
    },

    async addUser(user) {
      let newUser;
      try {
        const userDTO = new UserInsertDTO(user.username, user.password, user.role);
        newUser = await UserService.add(userDTO);
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
          return;
        }
        this.errorMessage = "Problem during request handling!";
        return;
      }
      this.successMessage = 'User added successfully!';
      this.users.push(this.convertUserToDTo(newUser));
    },

    async updateUser(user) {
      let editedUser;
      try {
        const userDTO = new UserUpdateDTO(user.id, user.username, user.password, user.role);
        editedUser = await UserService.update(userDTO);
      } catch (e) {
        if (e instanceof BackendError) {
          this.errorMessage = e.message;
          return;
        }
        this.errorMessage = "Problem during request handling!";
        return;
      }
      if (editedUser === undefined)
        return;
      this.successMessage = 'User data is changed!';
      Object.assign(this.users[this.editedIndex], this.convertUserToDTo(editedUser));
    },
  },
}
</script>

<style scoped>
.v-data-table >>> tr:hover {
  background: #fbd7a1 !important;
}
</style>